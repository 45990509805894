<template>
  <v-row justify="center">
    <v-dialog v-model="isActive" max-width="500px" persistent scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ model.id ? 'Editar' : 'Nuevo' }} Usuario
          </span>
        </v-card-title>
        <v-card-title>
          <h5 v-if="edit">
            Si requieres actualizar el correo electrónico, es necesario crear un nuevo usuario.
          </h5>
        </v-card-title>
        <v-card-text class="mt-4">
          <validation-observer ref="form">
            <v-container >
              <div class="row">
                <div class="col-12 custom-col">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Nombre Completo"
                    vid="name"
                    rules="required|max:80"
                  >
                    <v-text-field
                      v-model="model.name"
                      type="text"
                      outlined
                      dense
                      :error-messages="errors"
                      label="Nombre Completo"
                      required
                    />
                  </validation-provider>
                </div>
                <div class="col-12 custom-col" v-if="!edit">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email|max:80"
                  >
                    <v-text-field
                      v-model="model.email"
                      type="email"
                      outlined
                      dense
                      :error-messages="errors"
                      label="Email"
                      required
                    />
                  </validation-provider>
                </div>
                <div class="col-12 custom-col" v-if="!edit">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Confirmar Email"
                    vid="email_confirmation"
                    rules="required|email|max:80|confirmed:email"
                  >
                    <v-text-field
                      v-model="model.email_confirmation"
                      type="email"
                      outlined
                      dense
                      :error-messages="errors"
                      label="Confirmar Email"
                      required
                    />
                  </validation-provider>
                </div>
              </div>
            </v-container>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveExpositorUser">
            Aceptar
          </v-btn>
          <v-btn color="red" text @click="close">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import { EXPOSITOR_URL } from '@/constants/ServicesConstants'
import formOperationsMixin from '@/mixins/formOperationsMixin'

export default {
  name: 'ExpositorModal',
  props: {
    user: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isActive: false,
      model: {
        name: null,
        isActive: true,
        email: null,
        email_confirmation: null
      },
      edit: false
    }
  },
  methods: {
    close () {
      this.isActive = false
      this.clean()
    },
    clean () {
      this.$refs.form.reset()
      this.model = {
        name: null,
        isActive: null,
        email: null,
        email_confirmation: null
      }
    },
    open (user) {
      if (user) {
        this.model = user
        this.model.email = user.username
        this.edit = true
      } else {
        this.edit = false
      }

      this.isActive = true
    },
    saveExpositorUser () {
      this.executeWithFormValidation(() => {
        const formData = this.getFormData()
        if (this.model.id) {
          formData.append('_method', 'put')
          this.create(EXPOSITOR_URL + '/' + this.model.id, formData).then(() => {
            this.close()
            this.$emit('onClose')
          })
        } else {
          this.create(EXPOSITOR_URL, formData).then(() => {
            this.close()
            this.$emit('onClose')
          })
        }
      })
    }
  },
  mixins: [
    crudServiceMixin,
    formOperationsMixin,
    notificationMixin,
    loaderMixin
  ]
}
</script>
<style scoped>
  .custom-col{
    padding: 0px !important;
  }
</style>
