const headers = [
  {
    text: 'Nombre Completo',
    value: 'name',
    sortable: false
  },
  {
    text: 'Email',
    value: 'username',
    sortable: false
  },
  {
    text: 'Rol',
    value: 'role.name',
    sortable: false
  },
  {
    text: 'Activo',
    value: 'isActive',
    sortable: false
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    width: '20%'
  }
]
export default headers
