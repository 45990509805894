<template>
  <div>
    <banner
      :title="`${user.fairName} - Módulo Expositor - ${user.companyName}`"
      type="banner--admin"
      :options="{padding: '30px'}">
    </banner>
    <div class="pt-3 px-7">
      <div class="row">
        <div class="col-12 d-flex align-center justify-space-between">
          <h2>Gestor de Usuarios Aplicativo Móvil</h2>
          <v-btn
            color="secondary"
            class="text--primary text-none elevation-0"
            @click="openExpositorModal()"
            dark>
            Nuevo Usuario
          </v-btn>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <v-text-field
            v-model="params.filter_name"
            label="Nombres/Apellidos"
            v-on:keyup.enter="search"
            outlined
            dense
            single-line
            hide-details
          ></v-text-field>
        </div>
        <div class="col-6">
          <v-text-field
            v-model="params.filter_email"
            label="Email"
            v-on:keyup.enter="search"
            outlined
            dense
            single-line
            hide-details
          ></v-text-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-end">
          <v-btn
            color="primary"
            @click="search"
            class="white--text text-none elevation-0 mr-2">
            <v-icon
              left
              dark>
              fa-search
            </v-icon>
            Buscar
          </v-btn>
          <v-btn
            color="default"
            @click="clean"
            class="text-none elevation-0">
            <v-icon
              left
              dark>
              fa-broom
            </v-icon>
            Limpiar
          </v-btn>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <data-table ref="expositors" :url="url" :params="params" :headers="headers">
            <template v-slot:item.isActive="{ item }">
              <v-switch v-model="item.isActive" hide-details dense class="ma-0" @click="onDisableUser(item)"></v-switch>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    class="ma-2 elevation-0"
                    v-bind="attrs"
                    v-on="on"
                    @click="openExpositorModal(item)"
                    small
                    color="primary"
                  >
                    <v-icon
                      center
                      dark
                      small
                    >
                      fa fa-edit
                    </v-icon>
                  </v-btn>
                </template>
                <span>Editar usuario</span>
              </v-tooltip>
            </template>
          </data-table>
        </div>
      </div>
      <expositor-modal ref="expositorModal" @onClose="onClose"/>
      <confirm-dialog
        :title="'Confirmación'"
        :description="`¿Deseas ${(selectedUser.isActive ? 'activar' : 'desactivar')}  el usuario?`"
        @onClose="closeDisableExpositorUserModal()"
        @onConfirm="disableExpositorUser()"
        :is-active="isConfirmDisableUserActive"
      />
    </div>
  </div>
</template>
<script>
import DataTable from '@/components/data-table/DataTable'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import { EXPOSITOR_USERS_URL, EXPOSITOR_URL } from '@/constants/ServicesConstants'
import headers from '@/views/private/expositors/data/Headers'
import sessionMixin from '@/mixins/sessionMixin'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import ExpositorModal from '@/views/private/expositors/components/ExpositorModal'
import Banner from '@/components/banner/Banner'
import ConfirmDialog from '@/components/confirm-dialog/ConfirmDialog'

export default {
  beforeRouteEnter (to, from, next) {
    const user = sessionMixin.methods.getUser()
    if (sessionMixin.methods.isSuperExpositorAdmin(user.roleId) || sessionMixin.methods.isExpositorAdmin(user.roleId)) {
      next()
    } else {
      next({ path: '/error-403' })
    }
  },
  components: {
    Banner,
    ConfirmDialog,
    DataTable,
    ExpositorModal
  },
  data () {
    return {
      headers,
      url: EXPOSITOR_USERS_URL,
      params: {
        filter_name: '',
        filter_email: ''
      },
      user: null,
      selectedUser: {},
      isConfirmDisableUserActive: false
    }
  },
  methods: {
    clean () {
      this.params.filter_name = null
      this.params.filter_email = null
      this.search()
    },
    managePlaces (item) {
      this.$router.push({
        name: 'places',
        params: { id: item.id }
      })
    },
    manageConferences (item) {
      this.$router.push({
        name: 'conferences',
        params: { id: item.id }
      })
    },
    onClose () {
      this.$refs.expositors.getDataFromApi()
    },
    openExpositorModal (item) {
      this.$refs.expositorModal.open(item)
    },
    search () {
      this.$refs.expositors.getDataFromApi()
    },
    onDisableUser (item) {
      this.selectedUser = item
      this.isConfirmDisableUserActive = true
    },
    disableExpositorUser () {
      this.isConfirmDisableUserActive = false
      this.update(EXPOSITOR_URL + '/enable', this.selectedUser).then(() => {
        this.$refs.expositors.getDataFromApi()
      })
    },
    closeDisableExpositorUserModal () {
      this.isConfirmDisableUserActive = false
      this.selectedUser.isActive = !this.selectedUser.isActive
    }
  },
  mixins: [
    crudServiceMixin,
    formOperationsMixin,
    loaderMixin,
    notificationMixin,
    sessionMixin
  ],
  name: 'Expositors',
  created () {
    this.user = JSON.parse(localStorage.getItem('userInfo')).user
  }
}
</script>
